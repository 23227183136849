<template>
<div class="page">
  <page-section>
      <heading-card :data="dataHeading"></heading-card>
      <div class="video">
        <iframe :src="video" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
      </div>
  </page-section>
  <page-section color="grey">
    <section-action :data="dataPumpAction">
      <div v-html="t('action.text')"></div>
    </section-action>  
  </page-section>
  <page-section id="projects">
    <project-gallery></project-gallery>
  </page-section>
  <page-section color="grey">
    <picture-gallery></picture-gallery>
  </page-section>




</div>
  
</template>

<script>

import HeadingCard from '@/components/HeadingCard';

import SectionAction from '@/components/section/SectionAction';

import PageSection from '@/components/PageSection';
import ProjectGallery from '@/components/projects/ProjectGallery';
import PictureGallery from '@/components/PictureGallery';
import { computed } from 'vue';

import {useI18n} from 'vue-i18n';


import {videos} from '@/composables/videos';
import { useStore } from 'vuex';

export default {
  components:{
    HeadingCard,
    PageSection,
    PictureGallery,
    ProjectGallery,
    SectionAction
  },
  setup(){
      const store = useStore();
      const {t} = useI18n({
          messages:{
            en: require('@/locales/en/projectpage.json'),
            fr: require('@/locales/fr/projectpage.json'),
            pt: require('@/locales/pt/projectpage.json'),
            sw: require('@/locales/sw/projectpage.json')
          }
      })

      const links = videos();

      const video = computed(() => 
          links[store.state.language]
      )

    const dataHeading = computed(() => {
      return {
      title: t('title'),
      image:'projects-header.jpg',
      alt: t('alt'),
      cta:{
          type:"scroll",
          target: 'projects',
          icon: 'ivory',
          main: t('cta.main'),
          text: t('cta.text')
      }
    }});

    const dataPumpAction = computed(() => {
      return {
        img: "project-village.jpg",
        quote: true,
        quoteFrom: t('place'),
        cta:{
            target: 'Pump',
            icon: 'pumpanddrink',
            main: t('action.cta.main'),
            text: t('action.cta.text'),
            
        }
      }
      }
    );

    return {
      dataHeading,
      dataPumpAction,
      t,
      video
    }
  }
  
}
</script>

<style lang='scss' scoped>
@import '../style/main';
    .video{
        position: relative;
        margin: 0 auto;
        padding-top: $page-max-width*0.5625; // 16:9
        width: 100%;
        height: 0;

        @include respond(phone){
          padding-top: 56.25%; // 16:9
        }


        & iframe{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }


</style>
