<template>
    <div class="presentation" @click="toggleContent">
        <div class="presentation__content">
            <img :src="require(`@/assets/icons/${data.icon}`)" alt="">
            <h2>
                {{data.titleDescr}}
            </h2>
        </div>
        <div class="presentation__image">
            <img :src="require(`@/assets/gallery/pump/${data.image}`)" alt="">
        </div>
    </div>

    <div v-if="data.content && displayContent" class="presentation__data">
        <div v-for="item in data.content" class="presentation__data--item">
            <h2>
                {{item.title}}
            </h2>
            <template v-if="item.type === 'text'" v-for="(sub, k) in item.text" :key="k">
                <p v-if="sub.type === 'p'" v-html="sub.content"></p>
                <ul v-if="sub.type === 'list'">
                    <li v-for="li in sub.content" v-html="`- ${li}`">
                    </li>
                </ul>
            </template>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
export default {
    props:["data"],
    setup(){
        const displayContent = ref(true);

        const toggleContent = () => {
            displayContent.value = !displayContent.value;
        }
        return {displayContent, toggleContent};
    }
}
</script>

<style lang='scss' scoped>
@import '../../style/main';
.presentation{
    cursor: pointer;
    display: flex;
    background-color: $color-white;
    box-shadow: $shadow;
    width: 100%;
    margin-bottom: 3rem;

    @include respond(phone){
        flex-direction: column;
    }

    &__content{
        flex: 1;

        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
        @include respond(phone){
            padding: 4rem;
        }

        & img{
            width: 15rem;
            height: auto;
            margin-bottom: 3rem;

            @include respond(phone){
                height: 7rem;
                width: auto;
            }
        }

        & h2{
            text-transform: uppercase;
            letter-spacing: .1rem;
            text-align: center;
        }
    }

    &__image{
        flex: 0 0 50%;

        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        
        }
    }

    &__data{

        &--item{
            &:not(:last-child){
                margin-bottom: 3rem;
            }

        }

        & h2{
            text-transform: uppercase;
            letter-spacing: .1rem;
            margin-bottom: 1rem;
        }

        & ul{
             margin-bottom: 1.2rem;
        }

        & p{
            margin-bottom: 1.2rem !important;
        }

        & li{
            list-style: none;
            margin-bottom: .5rem;
        }
    }
}

</style>