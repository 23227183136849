<template>
    <div class="feature">
        <img :src="require(`@/assets/icons/${data.icon}`)" alt="">
        <h2>{{data.title}}</h2>
        <h3>{{data.descr}}</h3>
        <button @click="scrollTo(data.id)">
            {{t('cta')}}
        </button>
    </div>
</template>

<script>
import {smoothScroll} from '@/composables/scroll';

import {useI18n} from 'vue-i18n';
export default {
    props:['data'],
    setup(){
        const {t} = useI18n({
            messages:{
              en: {
                  "cta": "Learn More"
              },
              fr: {
                  "cta": "En savoir plus"
              },
              pt:{
                  "cta": "Leia mais"
              },
              sw:{
                  "cta": "Jifunze zaidi"
              }
            }
        });
        const scrollTo = (target) => {
            smoothScroll(target);
        };

        return {scrollTo, t}
    }
}
</script>

<style lang='scss' scoped>

 @import '../../style/main';

.feature{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-white;
    box-shadow: $shadow;
    flex: 0 0 31%;
    border-radius: 7px;
    margin-bottom: 3.5%;
    padding: 4rem 2rem;

    & img{
        height: 10rem;
        width: 10rem;
        margin-bottom: 2rem;
    }

    & h2{
        text-transform: uppercase;
        margin-bottom: .8rem;
        text-align: center;
    }

    & h3{
        text-align: center;
        font-weight: 300;
        font-size: 1.4rem;
    }

    & button{
        margin-top: 2rem;
        @include buttonNoStyle;
        border: 1px solid $color-primary;
        padding: .8rem 2rem;
        border-radius: 5px;

        text-transform: uppercase;
        letter-spacing: .1rem;
        box-shadow: $shadow;

        transition: all .2s;

        &:hover{
            transform: scale(1.06);
        }
    }
}

</style>