<template>
    <section-title>{{t('title')}}</section-title>
    <template v-for="(proj, i) in projects" :key="proj.project.name">
        <project-presentation :data="proj"></project-presentation>
    </template>
</template>

<script>

import ProjectPresentation from '@/components/projects/ProjectPresentation';
import SectionTitle from '@/components/section/SectionTitle';

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';


export default {
    components: {
        ProjectPresentation,
        SectionTitle
    },
    setup(){
        const {t} = useI18n({
            messages:{
              en: require('@/locales/en/project.json'),
              fr: require('@/locales/fr/project.json'),
              pt: require('@/locales/pt/project.json'),
              sw: require('@/locales/sw/project.json')
            }
        });

        const projects = computed(() => [
        {
                project:{
                    name: t('iv.name'),
                    place:  t('iv.place'),
                    when:  t('iv.when'),
                    countryImage:"ivory.png",
                    image: "pic-gallery-9.jpg",
                    alt:  t('iv.alt'),
                    units: "1000",
                    ongoin: false
                },
                features:[
                    {
                        title: t('iv.features.application.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "water.svg",
                                text:  t('iv.features.application.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "pumpanddrink.svg",
                                text: t('iv.features.application.content[1].text')
                            }
                        ]
                    },
                    {
                        title:t('iv.features.mission.title'),
                        type:"text",
                        content:[
                            {
                                type: "p",
                                text: t('iv.features.mission.content[0].text')
                            }
                        ]
                    },
                    {
                        title:t('iv.features.characteristics.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "tap.svg",
                                text:t('iv.features.characteristics.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "borehole.svg",
                                text:t('iv.features.characteristics.content[1].text')
                            },{
                                type:"bullet",
                                icon: "solar.svg",
                                text:t('iv.features.characteristics.content[2].text')
                            },{
                                type:"bullet",
                                icon: "watertank.svg",
                                text:t('iv.features.characteristics.content[3].text')
                            },{
                                type:"bullet",
                                icon: "light.svg",
                                text:t('iv.features.characteristics.content[4].text')
                            }
                        ]
                    },
                    {
                        title:t('iv.features.partners.title'),
                        type:"ul",
                        content:[
                            {
                                type:"list",
                                text: "Estia Synergie"
                            },
                            {
                                type:"list",
                                text:"Sinoqualis"
                            },
                             {
                                type:"list",
                                text:"Dosatron"
                            }
                        ]
                    },
                    {
                        title:t('iv.features.pictures.title'),
                        type:"images",
                        display: false,
                        content:[
                            {
                                name:"project-iv-1.jpg"
                            },
                            {
                                name:"project-iv-2.jpg"
                            },
                            {
                                name:"project-iv-3.jpg"
                            },
                            {
                                name:"project-iv-4.jpg"
                            },
                            {
                                name:"project-iv-5.jpg"
                            }
                        ]
                    }
                ]
            },
        {
                project:{
                    name: t('togo.name'),
                    place:  t('togo.place'),
                    when:  t('togo.when'),
                    countryImage:"togo.png",
                    image: "project-togo-0.jpg",
                    alt:  t('togo.alt'),
                    units: "2",
                    ongoin: false
                },
                features:[
                    {
                        title: t('togo.features.application.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "water.svg",
                                text:  t('togo.features.application.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "pumpanddrink.svg",
                                text: t('togo.features.application.content[1].text')
                            }
                        ]
                    },
                    {
                        title:t('togo.features.mission.title'),
                        type:"text",
                        content:[
                            {
                                type: "p",
                                text: t('togo.features.mission.content[0].text')
                            }
                        ]
                    },
                    {
                        title:t('togo.features.characteristics.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "tap.svg",
                                text:t('togo.features.characteristics.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "borehole.svg",
                                text:t('togo.features.characteristics.content[1].text')
                            },{
                                type:"bullet",
                                icon: "solar.svg",
                                text:t('togo.features.characteristics.content[2].text')
                            },{
                                type:"bullet",
                                icon: "watertank.svg",
                                text:t('togo.features.characteristics.content[3].text')
                            },{
                                type:"bullet",
                                icon: "light.svg",
                                text:t('togo.features.characteristics.content[4].text')
                            }
                        ]
                    },
                    {
                        title:t('togo.features.partners.title'),
                        type:"ul",
                        content:[
                            {
                                type:"list",
                                text: "Advansos"
                            },
                            {
                                type:"list",
                                text:"Sinoqualis"
                            },
                             {
                                type:"list",
                                text:"Dosatron"
                            }
                        ]
                    },
                    {
                        title:t('togo.features.pictures.title'),
                        type:"images",
                        content:[
                            {
                                name:"project-togo-1.jpg"
                            },
                            {
                                name:"project-togo-2.jpg"
                            },
                            {
                                name:"project-togo-3.jpg"
                            },
                            {
                                name:"project-togo-6.jpg"
                            },
                            {
                                name:"project-togo-7.jpg"
                            }
                        ]
                    }
                ]
            },
            {
                project:{
                    name: t('drc.name'),
                    place:  t('drc.place'),
                    when:  t('drc.when'),
                    countryImage:"congo-dm.png",
                    image: "projet-drc.jpg",
                    alt:  t('drc.alt'),
                    units: "124",
                    ongoin: true
                },
                features:[
                    {
                        title: t('drc.features.application.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "water.svg",
                                text:  t('drc.features.application.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "pumpanddrink.svg",
                                text: t('drc.features.application.content[1].text')
                            }
                        ]
                    },
                    {
                        title:t('drc.features.mission.title'),
                        type:"text",
                        content:[
                            {
                                type: "p",
                                text: t('drc.features.mission.content[0].text')
                            }
                        ]
                    },
                    {
                        title:t('drc.features.characteristics.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "tap.svg",
                                text:t('drc.features.characteristics.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "borehole.svg",
                                text:t('drc.features.characteristics.content[1].text')
                            },{
                                type:"bullet",
                                icon: "solar.svg",
                                text:t('drc.features.characteristics.content[2].text')
                            },{
                                type:"bullet",
                                icon: "watertank.svg",
                                text:t('drc.features.characteristics.content[3].text')
                            },{
                                type:"bullet",
                                icon: "light.svg",
                                text:t('drc.features.characteristics.content[4].text')
                            }
                        ]
                    },
                    {
                        title:t('drc.features.partners.title'),
                        type:"ul",
                        content:[
                            {
                                type:"list",
                                text: "Stever Construct"
                            },
                            {
                                type:"list",
                                text:"Sinoqualis"
                            },
                             {
                                type:"list",
                                text:"Dosatron"
                            }
                        ]
                    },
                    {
                        title:t('drc.features.pictures.title'),
                        type:"images",
                        content:[
                            {
                                name:"projet-drc-gallery-1.jpg"
                            },
                            {
                                name:"projet-drc-gallery-2.jpg"
                            },
                            {
                                name:"projet-drc-gallery-3.jpg"
                            },
                            {
                                name:"projet-drc-gallery-4.jpg"
                            },
                            {
                                name:"projet-drc-gallery-5.jpg"
                            }
                        ]
                    }
                ]
            },
            {
                project:{
                    name: t('cca.name'),
                    place:  t('cca.place'),
                    when:  t('cca.when'),
                    countryImage:"ivory.png",
                    image: "project-cca.jpg",
                    alt:  t('cca.alt'),
                    units: "150"
                },
                features:[
                    {
                        title: t('cca.features.application.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "water.svg",
                                text:  t('cca.features.application.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "pumpanddrink.svg",
                                text: t('cca.features.application.content[1].text')
                            }
                        ]
                    },
                    {
                        title:t('cca.features.mission.title'),
                        type:"text",
                        content:[
                            {
                                type: "p",
                                text: t('cca.features.mission.content[0].text')
                            }
                        ]
                    },
                    {
                        title:t('cca.features.characteristics.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "tap.svg",
                                text:t('cca.features.characteristics.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "borehole.svg",
                                text:t('cca.features.characteristics.content[1].text')
                            },{
                                type:"bullet",
                                icon: "solar.svg",
                                text:t('cca.features.characteristics.content[2].text')
                            },{
                                type:"bullet",
                                icon: "watertank.svg",
                                text:t('cca.features.characteristics.content[3].text')
                            },{
                                type:"bullet",
                                icon: "light.svg",
                                text:t('cca.features.characteristics.content[4].text')
                            }
                        ]
                    },
                    {
                        title:t('cca.features.partners.title'),
                        type:"ul",
                        content:[
                            {
                                type:"list",
                                text: "Estia Synergie"
                            },
                            {
                                type:"list",
                                text:"Sinoqualis"
                            },
                             {
                                type:"list",
                                text:"Dosatron"
                            }
                        ]
                    },
                    {
                        title:t('cca.features.pictures.title'),
                        type:"images",
                        content:[
                            {
                                name:"pic-gallery-6.jpg"
                            },
                            {
                                name:"pic-gallery-7.jpg"
                            },
                            {
                                name:"pic-gallery-8.jpg"
                            },
                            {
                                name:"pic-gallery-9.jpg"
                            },
                            {
                                name:"pic-gallery-1.jpg"
                            }
                        ]
                    }
                ]
            },
             {
                project:{
                   name: t('ccc.name'),
                    place:  t('ccc.place'),
                    when:  t('ccc.when'),
                    countryImage:"ivory.png",
                    image: "project-ccc.jpg",
                    alt:  t('ccc.alt'),
                    units: "150"
                },
                features:[
                    {
                        title: t('ccc.features.application.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "water.svg",
                                text: t('ccc.features.application.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "pumpanddrink.svg",
                                text: t('ccc.features.application.content[1].text')
                            }
                        ]
                    },
                    {
                        title:t('ccc.features.mission.title'),
                        type:"text",
                        content:[
                            {
                                type: "p",
                                text: t('ccc.features.mission.content[0].text')
                            }
                        ]
                    },
                    {
                        title:t('ccc.features.characteristics.title'),
                        type:"text",
                        content:[
                            {
                                type:"bullet",
                                icon: "tap.svg",
                                text:t('ccc.features.characteristics.content[0].text')
                            },
                            {
                                type:"bullet",
                                icon: "borehole.svg",
                                text:t('ccc.features.characteristics.content[1].text')
                            },{
                                type:"bullet",
                                icon: "solar.svg",
                                text:t('ccc.features.characteristics.content[2].text')
                            },{
                                type:"bullet",
                                icon: "watertank.svg",
                                text:t('ccc.features.characteristics.content[3].text')
                            },{
                                type:"bullet",
                                icon: "agriculture.svg",
                                text:t('ccc.features.characteristics.content[4].text')
                            },{
                                type:"bullet",
                                icon: "light.svg",
                                text:t('ccc.features.characteristics.content[5].text')
                            }
                        ]
                    },
                    {
                        title:t('ccc.features.partners.title'),
                        type:"ul",
                        content:[
                            {
                                type:"list",
                                text: "Estia Synergie"
                            },
                            {
                                type:"list",
                                text:"Sinoqualis"
                            },
                             {
                                type:"list",
                                text:"Dosatron"
                            }
                        ]
                    },
                    {
                        title:t('ccc.features.pictures.title'),
                        type:"images",
                        content:[
                            {
                                name:"project-ccc-1.jpg"
                            },
                            {
                                name:"project-ccc-2.jpg"
                            },
                            {
                                name:"project-ccc-3.jpg"
                            },
                            {
                                name:"project-ccc-4.jpg"
                            },
                            {
                                name:"project-ccc-5.jpg"
                            }
                        ]
                    }
                ]
            }
        ]);
        return {projects, t}
    }

}
</script>

<style>

</style>