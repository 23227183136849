<template>
  <section-title>{{t('title')}}</section-title>
  <div class="gallery">
    <div v-for="col in cols" :key="col.name" class="gallery__col">
      <div v-for="image in col.images" class="gallery__col--img" @click="bigPicture(image.path)">
        <img  :src="image.src" alt="">
      </div>
      
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/section/SectionTitle';
import { onMounted, ref } from 'vue';
import {useI18n} from 'vue-i18n';
import { useStore } from 'vuex';
export default {
  components: {
        SectionTitle
  },
  setup(){
    const store = useStore();
     const {t} = useI18n({
            messages:{
              en: {
                  "title": "Missions Gallery"
              },
              fr: {
                  "title": "Photos de missions"
              },
              pt: {
                  "title": "Galeria de Missões"
              },
              sw:{
                "title": "Nyumba ya sanaa ya Misheni"
              }
            }
        });
      let cols = ref([
        {
          name:"col-1",
          height: 0,
          images: []
        },
         {
          name:"col-2",
          height: 0,
          images: []
        },
         {
          name:"col-3",
          height: 0,
          images: []
        }
      ]);

    const bigPicture = (image) => {
      console.log('enlarge', image);
      store.state.popup.display =  true
      store.state.popup.type =  'image'
      store.state.popup.data = {
        gallery: gallery,
        image
      }
    }


    onMounted(() => {


      for(let i of gallery){
        let image = new Image();
        image.onload = function () {
          //On trie de la plus petite hauteur à la plus grande
          cols.value.sort((a,b) => (a.height > b.height) ? 1 : ((b.height > a.height) ? -1 : 0))

          cols.value[0].images.push({
            src: image.src,
            path: i.src
          });
          cols.value[0].height += image.height/image.width;

        };
        image.src = `/gallery/images/${i.src}`
      }

    })
    const gallery = [
      {
        src:"gallery-1.jpg",
        alt:""
      },
       {
        src:"gallery-2.jpg",
        alt:""
      },
       {
        src:"gallery-3.jpg",
        alt:""
      },
       {
        src:"gallery-4.jpg",
        alt:""
      },
       {
        src:"gallery-5.jpg",
        alt:""
      },
       {
        src:"gallery-6.jpg",
        alt:""
      },
      //  {
      //   src:"gallery-7.jpg",
      //   alt:""
      // },
       {
        src:"gallery-8.jpg",
        alt:""
      },
       {
        src:"gallery-9.jpg",
        alt:""
      },
       {
        src:"gallery-10.jpg",
        alt:""
      },
       {
        src:"gallery-11.jpg",
        alt:""
      },
       {
        src:"gallery-12.jpg",
        alt:""
      },
       {
        src:"gallery-13.jpg",
        alt:""
      },
      //  {
      //   src:"gallery-14.jpg",
      //   alt:""
      // },
       {
        src:"gallery-15.jpg",
        alt:""
      },
       {
        src:"gallery-16.jpg",
        alt:""
      },
      //  {
      //   src:"gallery-17.jpg",
      //   alt:""
      // },
       {
        src:"gallery-18.jpg",
        alt:""
      },
       {
        src:"gallery-19.jpg",
        alt:""
      },
       {
        src:"gallery-20.jpg",
        alt:""
      },
       {
        src:"gallery-21.jpg",
        alt:""
      },
      //  {
      //   src:"gallery-22.jpg",
      //   alt:""
      // }
    ];
    return {
      bigPicture,
      cols,
      t
    }
  }
}
</script>

<style lang='scss' scoped>

@import '@/style/main';
.gallery{
  display: flex;
  justify-content: space-between;

    @include respond(phone){
      flex-direction: column;
    }
  &__col{
    flex: 0 0 32%;

    &--img{
      overflow: hidden;
      cursor: zoom-in;
      margin-bottom: 6%;
      
      
    }

    & img{
      display: block;
      width: 100%;
      height: auto;
      

      transition: all .2s;
      &:hover {
        transform: scale(1.06);
      }

    }
  }
}

</style>