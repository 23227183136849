<template>
  <div class="page">
    <page-section>
      <div
        class="product__header"
        :style="
          `background-image: url('${require('../assets/gallery/products2.jpg')}')`
        "
      >
        <div class="product__header--title">
          <h1 v-html="t('title')"></h1>
        </div>
      </div>
      <section-title>{{ t("subtitle") }}</section-title>
      <p>
        {{ t("content[0]") }}
      </p>
      <p>
        {{ t("content[1]") }}
      </p>
      <div class="comparison">
        <ul class="comparison__line comparison__line--header">
          <li></li>
          <li>
            <img src="@/assets/product/pd500.jpg" />
            P&D 500
          </li>
          <li>
            <img src="@/assets/product/pd2000.jpg" />
            P&D 2000
          </li>
          <li><img src="@/assets/product/pd5000.jpg" />P&D 5000</li>
        </ul>
        <ul class="comparison__line comparison__line--main">
          <li class="comparison__line--side">Daily Production</li>
          <li>15m³/day</li>
          <li>25m³/day</li>
          <li>50m³/day</li>
        </ul>
        <ul class="comparison__line comparison__line--main">
          <li class="comparison__line--side">Data Monitoring</li>
          <li>
            <Svg icon="check" size="2"></Svg>
          </li>
          <li><Svg icon="check" size="2"></Svg></li>
          <li><Svg icon="check" size="2"></Svg></li>
        </ul>
        <ul class="comparison__line comparison__line--main">
          <li class="comparison__line--side">Tank Storage Capacity</li>
          <li>8,000 liters</li>
          <li>8,000 liters</li>
          <li>27,000 Liters </li>
        </ul>
        <ul class="comparison__line comparison__line--main comparison__line--parent">
          <li class="comparison__line--side">Treatment</li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <ul class="comparison__line comparison__line--sub">
          <li class="comparison__line--side comparison__line--item">Water disinfection</li>
          <li><Svg icon="check" size="2"></Svg></li>
          <li><Svg icon="check" size="2"></Svg></li>
          <li><Svg icon="check" size="2"></Svg></li>
        </ul>
        <ul class="comparison__line comparison__line--sub">
          <li class="comparison__line--side comparison__line--item">Heavy metal processing</li>
          <li><Svg icon="uncheck" class="u-color-red" size="2"></Svg></li>
          <li><Svg icon="uncheck" class="u-color-red" size="2"></Svg></li>
          <li><Svg icon="check" size="2"></Svg></li>
        </ul>
        <ul class="comparison__line comparison__line--sub">
          <li class="comparison__line--side comparison__line--item">Organic waste</li>
          <li><Svg icon="uncheck" class="u-color-red" size="2"></Svg></li>
          <li><Svg icon="uncheck" class="u-color-red" size="2"></Svg></li>
          <li><Svg icon="check" size="2"></Svg></li>
        </ul>
        <ul class="comparison__line comparison__line--sub">
          <li class="comparison__line--side comparison__line--item">Microplastics</li>
          <li><Svg icon="uncheck" class="u-color-red" size="2"></Svg></li>
          <li><Svg icon="uncheck" class="u-color-red" size="2"></Svg></li>
          <li><Svg icon="check" size="2"></Svg></li>
        </ul>
        <ul class="comparison__line comparison__line--main">
          <li class="comparison__line--side">Solar Panel Max Power</li>
          <li>1000 W</li>
          <li>2500 W</li>
          <li>6600 W</li>
        </ul>
        <ul class="comparison__line comparison__line--main">
          <li class="comparison__line--side">Distribution points</li>
          <li>1</li>
          <li>1</li>
          <li>4</li>
        </ul>
      </div>

      <!-- <div class="pump__features">
            <pump-card v-for="i in features" :key="i.icon" :data="i"></pump-card>
          </div>
          <div class="pump__impulse">
            <div class="pump__impulse--image">
              <img src="@/assets/icons/solar-impulse.png" alt="">
            </div>
            <div class="pump__impulse--content">
              <h2>
                {{t('impulse.title')}}
              </h2>
              <p>
                {{t('impulse.text')}}
              </p>
              <a href="https://solarimpulse.com/efficient-solutions/pump-drink" target="_blank">
                <Svg icon="right-arrow" className="pump__impulse--svg"></Svg>
                {{t('impulse.cta')}}
              </a>
            </div>
          </div> -->
    </page-section>
  </div>
</template>

<script>
import HeadingCard from "@/components/HeadingCard";
import PageSection from "@/components/PageSection";
import SectionTitle from "@/components/section/SectionTitle";
import PictureLine from "@/components/PictureLine";
import PumpCard from "@/components/pump/PumpCard";
import PumpFeature from "@/components/pump/PumpFeature";

import Svg from "@/components/utils/Svg";
import { useI18n } from "vue-i18n";
import { computed } from "vue";

export default {
  components: {
    HeadingCard,
    PageSection,
    PictureLine,
    PumpCard,
    PumpFeature,
    SectionTitle,
    Svg,
  },
  setup() {
    const { t } = useI18n({
      messages: {
        en: {
          title: "Our range of products",
          subtitle: "Discover our different Pump&Drink available!",
          content: [
            "To reach more and more people, we have expanded our range of Pump&Drink®. Thanks to our 2 new units, we have now the capacity to supply drinking water to small communities, villages, peri urban areas.",
            "More information coming soon !",
          ],
        },
        //   en: require('@/locales/en/pump.json'),
        //   fr: require('@/locales/fr/pump.json'),
        //   pt: require('@/locales/pt/pump.json'),
        //   sw: require('@/locales/sw/pump.json')
      },
    });


    
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
@import "../style/main";

.comparison {
  display: flex;
  flex-direction: column;

  &__line {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
    column-gap: 2rem;

    @include respond(phone){
      display: grid;
      grid-template-columns: 1fr 1fr 1fr; 
      grid-template-rows: 1fr 1fr; 
      gap:1rem 0px; 
      grid-template-areas: 
        "title title title"
        ". . ."; 
    }

    &--side{
      align-items: flex-start !important;
      @include respond(phone){
        width: 100%;
        // background-color: red;
        grid-area: title;
      }
    }

    &--parent{
      margin-bottom: 0 !important;
      @include respond(phone){
        display: flex;
      }
    }

    &:not(:last-child) {
      padding-bottom: 1rem;
      margin-bottom: 1rem;
        // border-bottom: 1px solid currentColor;
      
    }

    & li {
      list-style: none;
      display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      &:first-child {
        font-weight: 700 !important;
      }
      
    }

    &--sub {
      color: $color-secondary;

      & li:first-child {
        padding-left: 1rem;
        font-weight: 400 !important;
          @include respond(phone){
          padding-left: 0;
        }
      }
    }

   

    &--header {
      font-weight: 700;
      font-size: 1.8rem;

      @include respond(phone){
        grid-template-columns: 0fr 1fr 1fr 1fr;
        grid-template-rows: 1fr; 
        column-gap: .5em;
      }

      & li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & img {
          width: 100%;
          height: auto;
          margin-bottom: 1rem;
        }
      }
    }

   
  }
}

.product {
  &__header {
    height: 50rem;
    position: relative;

    background-position: center;
    background-size: cover;
    margin-bottom: 6rem;

    @include respond(phone) {
      height: 30rem;
    }

    &--title {
      position: absolute;
      top: 5rem;
      left: 0;
      padding: 3rem 5rem;
      background-color: rgba($color-white, 0.8);

      @include respond(phone) {
        width: 100%;
        padding: 2rem;
        // bottom: 0;
        top: 50%;

        transform: translateY(-50%);
      }

      & h1 {
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-size: 4rem;
        line-height: 4.8rem;
        @include respond(phone) {
          text-align: center;
        }
      }
    }
  }

  &__features {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond(phone) {
      flex-direction: column;
    }
  }

}


</style>
