<template>
  <div class="project">
        <div class="project__header">
            <div class="project__header--img">
                <img :src="require(`@/assets/gallery/${data.project.image}`)" :alt="data.project.alt">
            </div>
            <div class="project__header--content">
                <h2>
                    {{data.project.name}}
                </h2>
                <h3>
                    {{data.project.place}} - {{data.project.when}}
                </h3>
                <img :src="require(`@/assets/icons/${data.project.countryImage}`)" alt="" class="project__header--content-img">
                <div class="project__header--units">
                    <img src="@/assets/icons/pumpanddrink.svg" alt="">
                    {{data.project.units}} {{t('unit')}} <span v-if="data.project.ongoin" style="margin-left:6px"> - {{t('ongoing')}}</span> 
                </div>
            </div>
        </div>
        <div class="project__content">
            <div v-for="feat in data.features" :key="feat.title" class="project__content--item">
                <h2 v-if="feat.display !==false">
                    {{feat.title}}
                </h2>
                
                <template v-if="feat.type==='text'" v-for="(item, i) in feat.content" :key="`item-${i}`">
                    <div v-if="item.type === 'bullet'" class="project__content--bullet">
                        <img :src="require(`@/assets/icons/${item.icon}`)" alt="">
                        {{item.text}}
                    </div>
                    <p v-if="item.type === 'p'" v-html="item.text"> </p>
                    <p v-if="item.type === 'list'">
                       - {{item.text}}
                    </p>
                </template>
                <template v-else-if="feat.type==='images'">
                    <picture-line v-if="feat.display !==false" :data="feat.content"></picture-line>
                </template>
                <template v-else-if="feat.type==='ul'">
                    <ul>
                        <li v-for="(item, i) in feat.content" :key="`listitem-${i}`">
                        - {{item.text}}
                        </li>
                    </ul>
                </template>

            </div>
        </div>
  </div>

</template>

<script>
import PictureLine from '../PictureLine';

import {useI18n} from 'vue-i18n';
export default {
    components:{
        PictureLine
    },
    props:['data'],
    setup(){
        const {t} = useI18n({
            messages:{
              en: {
                  "unit": "units",
                  "ongoing": 'ongoing'
              },
              fr: {
                  "unit": "unités",
                  "ongoing": 'en cours'
              },
              pt:{
                  "unit": "unidades",
                  "ongoing": 'em curso'
              },
              sw:{
                  "unit": "vitengo",
                  "ongoing": 'inayoendelea'
              }
            }
        });
        return {t}
    }
}
</script>

<style lang="scss" scoped>
@import '@/style/main';

.project{

    &:not(:last-child){
        border-bottom: 2px solid currentColor;
        margin-bottom: 4rem;
    }
    &__header{
        width: 100%;
        box-shadow: $shadow;
        background-color: $color-white;
        display: flex;

        @include respond(phone){
            flex-direction: column;
        }

        &--img{
            flex: 0 0 50%;
            & img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        &--content{
            position: relative;
            padding: 2rem;
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            & h2{
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: .1rem;
                margin-bottom: .6rem;
                text-align: center;
            }

            & h3{
                margin-bottom: 2rem;
                font-weight: 300;
                color:$color-secondary;
            }
            &-img{
                width: 20rem;
                @include respond(phone){
                    margin: 2rem 0 5rem 0;
                }
            }
        }

        &--units{
            position: absolute;
            bottom: 2rem;
            right: 2rem;

            display: flex;
            font-size: 1.8rem;
            text-transform: uppercase;
            align-items: center;
            height: 2.4rem;

            @include respond(phone){
                bottom: 1rem;
                right: 1rem;
                
            }
            & img{
                height: 100%;
                width: auto;
                margin-right: 1rem;
            }
        }

    }

    &__content{
        margin: 4rem 0;
        font-size: 1.6rem;

        & li{
            list-style: none;
        }

        &--item{

            & h2{
                text-transform: uppercase;
                font-size: 2rem;
                letter-spacing: .1rem;
                margin-bottom: 1rem;
            }

            & p{
                text-align: justify;
            }
            &:not(:last-child){
                margin-bottom: 3rem;
            }

            
            
        }

         &--bullet{
                display: flex;
                align-items: center;
                margin-bottom: 2rem;
                & img{
                    height: 2.5rem;
                    width: 3rem;
                    margin-right: 1rem;
                }

                
            }
    }
}

</style>